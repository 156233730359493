<template>
  <section class="about">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="text-uppercase">Naprawy Bezgotowkowe</h1>
      </div>
    </div>
    <div class="container mb-5">
      <h2>Bezgotówkowa naprawa pojazdu – wygodne rozwiązanie dla Ciebie!</h2>
      <p>
        Wypadek lub kolizja drogowa to stresujące wydarzenie, które wiąże się z wieloma
        formalnościami. Często największym wyzwaniem okazuje się proces likwidacji szkody oraz
        naprawa pojazdu. Na szczęście istnieje wygodne rozwiązanie – bezgotówkowa naprawa auta,
        którą oferujemy naszym Klientom.
      </p>

      <h2>Jak to działa?</h2>
      <p>
        Realizujemy naprawy bezgotówkowe i reprezentujemy naszych Klientów przed firmami
        ubezpieczeniowymi. Dzięki temu nie musisz martwić się skomplikowanymi procedurami – zajmiemy
        się wszystkim za Ciebie!
      </p>
      <p>
        Jedyną rzeczą, którą musisz zrobić, jest zgłoszenie szkody do swojego towarzystwa
        ubezpieczeniowego. Jeśli jednak jest to dla Ciebie problematyczne, możemy zrobić to za
        Ciebie! Po zgłoszeniu szkody cały proces likwidacji przejmujemy my – od kontaktu z
        ubezpieczycielem po kompleksową naprawę Twojego pojazdu.
      </p>

      <h2>Zakres naszych napraw</h2>
      <p>Oferujemy kompleksowe usługi w zakresie:</p>
      <ul>
        <li>
          <strong>Blacharstwa samochodowego</strong> – Naprawiamy wszelkie uszkodzenia karoserii,
          wgniecenia i odkształcenia powstałe w wyniku kolizji.
        </li>
        <li>
          <strong>Lakiernictwa samochodowego</strong> – Oferujemy profesjonalne lakierowanie
          pojazdów, dzięki czemu Twój samochód odzyska swój pierwotny wygląd.
        </li>
        <li>
          <strong>Mechaniki pojazdowej</strong> – Usuwamy szkody mechaniczne, aby pojazd działał
          sprawnie i bezpiecznie.
        </li>
      </ul>

      <h2>Dlaczego warto skorzystać z naprawy bezgotówkowej?</h2>
      <ol>
        <li>
          <strong>Oszczędność czasu</strong> – Nie musisz samodzielnie kontaktować się z
          ubezpieczycielem i śledzić procedur naprawy.
        </li>
        <li>
          <strong>Brak wydatków z własnej kieszeni</strong> – Koszty naprawy są rozliczane
          bezpośrednio z ubezpieczycielem.
        </li>
        <li>
          <strong>Profesjonalna obsługa</strong> – Dbamy o każdy etap likwidacji szkody, zapewniając
          wysoką jakość usług.
        </li>
        <li>
          <strong>Minimalizacja stresu</strong> – Formalności i negocjacje z firmą ubezpieczeniową
          przejmujemy my.
        </li>
      </ol>

      <h2>Postaw na wygodę i profesjonalizm</h2>
      <p>
        Jeśli Twój pojazd uległ uszkodzeniu, nie musisz się martwić formalnościami ani kosztami
        naprawy. Skorzystaj z bezgotówkowej naprawy i pozwól nam zająć się wszystkim za Ciebie!
        Dzięki naszemu doświadczeniu i współpracy z firmami ubezpieczeniowymi, proces ten
        przebiegnie sprawnie i bezproblemowo.
      </p>
      <p>Skontaktuj się z nami i przekonaj się, jak łatwa może być naprawa Twojego auta!</p>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Naprawy bezgotówkowe',
  },
});
</script>

<style lang="scss" scoped>
.jumbotron {
  background-size: cover;
  background: url(../assets/images/agreement-hero.jpg) center center;

  h1 {
    display: inline-block;
    padding: $grid-gutter-width / 2;
    font-size: 3rem;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>

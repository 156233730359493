<template>
  <section class="about">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="text-uppercase">Diagnostyka i serwis</h1>
      </div>
    </div>
    <div class="container mb-5">
      <h2>
        Profesjonalna diagnostyka i serwis samochodowy – kompleksowa opieka nad Twoim pojazdem
      </h2>
      <p>
        Dbanie o stan techniczny samochodu to klucz do bezpiecznej i komfortowej jazdy. Regularna
        diagnostyka oraz profesjonalny serwis pozwalają uniknąć poważnych awarii i kosztownych
        napraw. W naszym warsztacie oferujemy kompleksowe usługi diagnostyczne i serwisowe,
        zapewniając najwyższą jakość obsługi oraz precyzyjne wykrywanie usterek.
      </p>

      <h2>Zakres naszych usług serwisowych</h2>
      <p>Oferujemy szeroki wachlarz usług serwisowych, obejmujących:</p>
      <ul>
        <li>
          <strong>Przeglądy okresowe</strong> – kompleksowa kontrola stanu technicznego pojazdu
          zgodnie z zaleceniami producenta.
        </li>
        <li>
          <strong>Serwis olejowy</strong> – wymiana oleju silnikowego i filtrów dla optymalnej pracy
          silnika.
        </li>
        <li>
          <strong>Naprawy mechaniczne</strong> – usuwanie usterek w układzie napędowym, hamulcowym i
          zawieszeniu.
        </li>
      </ul>

      <h2>Dlaczego warto nam zaufać?</h2>
      <p>Wybierając nasz serwis, zyskujesz:</p>
      <ol>
        <li>
          <strong>Profesjonalne podejście</strong> – wykwalifikowani mechanicy z wieloletnim
          doświadczeniem.
        </li>
        <li>
          <strong>Szybki czas realizacji</strong> – sprawnie i terminowo wykonujemy wszystkie
          usługi.
        </li>
        <li><strong>Gwarancję jakości</strong> – na każdą usługę udzielamy gwarancji.</li>
        <li>
          <strong>Indywidualne podejście do klienta</strong> – doradzamy najlepsze rozwiązania
          dostosowane do Twojego pojazdu.
        </li>
      </ol>

      <h2>Skontaktuj się z nami</h2>
      <p>
        Nie czekaj na awarię – zadbaj o swoje auto już dziś! Skontaktuj się z nami i umów na
        diagnostykę lub serwis. Gwarantujemy rzetelność, precyzję oraz pełne zadowolenie z naszych
        usług!
      </p>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Diagnostyka i serwis',
  },
});
</script>

<style lang="scss" scoped>
.jumbotron {
  background-size: cover;
  background: url(../assets/images/car-service1-hero.jpg) center center;

  h1 {
    display: inline-block;
    padding: $grid-gutter-width / 2;
    font-size: 3rem;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>

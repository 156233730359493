<template>
  <section class="about">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="text-uppercase">Naprawy Powypadkowe</h1>
      </div>
    </div>
    <div class="container mb-5">
      <h2>Profesjonalne naprawy powypadkowe – przywracamy Twój pojazd do pełnej sprawności!</h2>
      <p>
        Wypadek drogowy to sytuacja stresująca, a jednym z najważniejszych kroków po kolizji jest
        profesjonalna naprawa pojazdu. Odpowiednio przeprowadzona naprawa powypadkowa zapewnia nie
        tylko estetykę samochodu, ale przede wszystkim jego bezpieczeństwo i pełną funkcjonalność. W
        naszym warsztacie oferujemy kompleksowe usługi naprawcze, które gwarantują najwyższą jakość
        i dbałość o każdy detal.
      </p>

      <h2>Nowoczesne technologie i doświadczenie</h2>
      <p>
        Nasze naprawy opierają się na najnowocześniejszych technologiach oraz wiedzy
        wykwalifikowanych specjalistów. Korzystamy z precyzyjnych urządzeń diagnostycznych oraz
        wysokiej jakości części zamiennych, aby Twój samochód odzyskał pierwotny stan techniczny i
        wizualny. Współpracujemy zarówno z klientami indywidualnymi, jak i z firmami
        ubezpieczeniowymi, co umożliwia szybkie i sprawne rozliczenie kosztów naprawy.
      </p>

      <h2>Zakres naszych usług</h2>
      <p>Oferujemy szeroki wachlarz usług napraw powypadkowych, w tym:</p>
      <ul>
        <li>
          <strong>Diagnostykę i ocenę szkód</strong> – dokładna analiza uszkodzeń pozwala zaplanować
          odpowiedni proces naprawczy.
        </li>
        <li>
          <strong>Naprawy blacharskie</strong> – profesjonalne prostowanie nadwozia, wymiana
          uszkodzonych elementów.
        </li>
        <li>
          <strong>Lakierowanie</strong> – nowoczesne techniki lakiernicze gwarantujące idealne
          dopasowanie koloru i trwałość powłoki.
        </li>
        <li>
          <strong>Naprawy mechaniczne</strong> – przywracanie pełnej sprawności technicznej pojazdu.
        </li>
        <li>
          <strong>Geometria zawieszenia</strong> – zapewnienie odpowiedniego ustawienia kół dla
          bezpieczeństwa i komfortu jazdy.
        </li>
        <li>
          <strong>Obsługę formalności ubezpieczeniowych</strong> – pomoc w zgłoszeniu szkody i
          rozliczeniu kosztów naprawy.
        </li>
      </ul>

      <h2>Dlaczego warto nam zaufać?</h2>
      <p>Wybierając nasz warsztat, zyskujesz:</p>
      <ol>
        <li>
          <strong>Gwarancję jakości</strong> – każda naprawa objęta jest gwarancją na wykonaną
          usługę.
        </li>
        <li>
          <strong>Szybki czas realizacji</strong> – dzięki sprawnej organizacji pracy minimalizujemy
          czas oczekiwania na gotowy pojazd.
        </li>
        <li>
          <strong>Użycie oryginalnych części lub wysokiej klasy zamienników</strong> – zapewniamy
          najwyższą jakość części zamiennych.
        </li>
        <li>
          <strong>Doświadczenie i profesjonalizm</strong> – nasi specjaliści to eksperci w swojej
          dziedzinie.
        </li>
      </ol>

      <h2>Skontaktuj się z nami</h2>
      <p>
        Jeśli Twój pojazd wymaga naprawy po kolizji, nie zwlekaj! Skontaktuj się z nami i powierz
        swój samochód w ręce profesjonalistów. Zadbamy o to, aby wrócił do Ciebie w pełni sprawny,
        bezpieczny i wyglądający jak nowy!
      </p>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Naprawy powypadkowe',
  },
});
</script>

<style lang="scss" scoped>
.jumbotron {
  background-size: cover;
  background: url(../assets/images/accident-hero.jpg) center center;

  h1 {
    display: inline-block;
    padding: $grid-gutter-width / 2;
    font-size: 3rem;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
